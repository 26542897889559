.shop-card-wrapper .slick-slide{
   margin-left: 1rem;

   margin-right: 1rem;
 }
@media(min-width:991px) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    overflow: hidden !important;
  }
  
  .shop-card-wrapper {
	.mat-mdc-tab-group {
		-webkit-box-align: center !important;
		-ms-flex-align: center !important;
		align-items: center !important;
	  }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-header-pagination {
      display: none !important;
    }

    .mat-mdc-tab-header {
      margin: 0 auto;
    }

    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
      border: 0;
    }

    .mat-mdc-tab-group.mat-primary .mat-ink-bar,
    .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
      background: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label {
      height: 37px;
      padding: 0 14px;
      cursor: pointer;
      min-width: auto;
      margin: 0 5px;
      border-radius: 5px;
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label-content {
        font-weight: bold !important;
        text-transform: uppercase;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-active.mat-tab-label {
      background: #fff;
      color: #000;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-list {
      margin-bottom: 3rem;
    }
   
  }
}
@media(max-width:990px){
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	.shop-card-wrapper  .mat-tab-header-pagination {
		display: flex !important;
	   }
}

@media(max-width:767px) {
  .card-container {
    .jp-card-container {
      width: 300px;
      margin: 0;
    }
    .jp-card {
      min-width: 285px;
    }
  }
}
